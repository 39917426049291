import ACTIONS from '../constants/actionsTypes';

const initialState = {
    address       : '',
    firstName     : '',
    lastName      : '',
    surname       : '',
    phoneNumber   : '',
    flat          : '',
    floor         : '',
    entrance      : '',
    clientComment : '',
    paymentType   : 'on_delivery',
    method        : 'courier',
    merchantId    : null,
    orderId       : null
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.SET_CHECKOUT_STATE:
            return {
                ...state,
                ...data
            };
        case ACTIONS.CLEAR_CHECKOUT_STATE:
            return initialState;
        default:
            return state;
    }
};
